import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

function Canonical() {
  const location = useLocation();
  const canonicalUrl = `https://igcarts.com${location.pathname}`;

  return (
    <Helmet>
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  );
}

export default Canonical;
