import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormAndButton from "../../components/shared/FormAndButton";
import Button from "../../components/shared/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useOutletContext } from "react-router-dom";
import {
  clearMedia,
  loadInstagramMedia,
} from "../../store/Slice/instagramServiceSlice";
import { orderSubmission } from "../../store/Slice/submitOrderSlice";
import { updateUserDetails } from "../../store/Slice/loginSlice";
import {
  ErrorModal,
  InfoModal,
  SuccessModal,
} from "../../components/shared/ToastModals";
import responseMessages from "../../utils/responseMessages";
import { useAccordionContext } from "../../SEO/AccordionContext";
import {
  instagramLikesSeoAccordion,
  instagramLikesSeoAccordionHeading,
} from "../../SEO/components/pagesAccordion";
import { InstagramLikesReview } from "../../SEO/components/customerReviews";
import { Helmet } from "react-helmet-async";

const InstagramLikes = () => {
  const dispatch = useDispatch();
  const { media } = useSelector((state) => state.instagramService);
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const { setQuantity } = useOutletContext();
  const { setAccordionData, setAccordionHeading, setReviews } =
    useAccordionContext();
  const [orderDetails, setOrderDetails] = useState({
    link: "",
    quantity: "",
    service_type: window.location.pathname,
  });
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [message, setMessage] = useState("");
  useEffect(() => {
    return () => {
      dispatch(clearMedia());
    };
  }, [dispatch]);
  useEffect(() => {
    setQuantity(0);
    setAccordionData(instagramLikesSeoAccordion);
    setAccordionHeading(instagramLikesSeoAccordionHeading);
    setReviews(InstagramLikesReview);
  }, []);
  const onClick = () => {
    dispatch(loadInstagramMedia(username));
  };

  const onChange = (e) => {
    setUsername(e.target.value);
  };
  const { quantity } = orderDetails;

  const onQuantityChange = (index, quantities, link, quantity) => {
    let sumOfQuantities = 0;
    Object.keys(quantities).forEach((item) => {
      if (typeof quantities[item] == "number") {
        sumOfQuantities += quantities[item];
      }
    });
    setQuantity(sumOfQuantities);
    setOrderDetails({ ...orderDetails, link, quantity });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!media) {
      toast.info("Please load media first.");
      return;
    }
    if (quantity < 100) {
      setInfoModalVisible(true);
      setMessage(responseMessages.minimumQuantityOf100);
      return;
    }
    try {
      setLoading(true);
      const response = await dispatch(orderSubmission(orderDetails));
      if (response.payload.code === 200 || response.payload.code === 201) {
        setQuantity(0);
        setSuccessModalVisible(true);
        setMessage(responseMessages.isAvailableOrderMessage);
        await dispatch(updateUserDetails());
        setUsername("");
        await dispatch(clearMedia());
        setOrderDetails({
          link: "",
          quantity: "",
          service_type: window.location.pathname,
        });
      } else {
        setQuantity(0);
        setErrorModalVisible(true);
        if (response.payload.message === '"link" is not allowed to be empty') {
          setMessage(responseMessages.linkClickMessage);
        } else if (response.payload.message === "Incorrect service ID") {
          setMessage(responseMessages.unavailableServiceDuetoId);
        } else if (response.payload.message === "insufficient wallet balance") {
          setMessage(responseMessages.insufficientBalance);
        } else {
          setMessage(responseMessages.orderFailure);
        }
      }
      setLoading(false);
    } catch (error) {
      setQuantity(0);
      setLoading(false);
      setErrorModalVisible(true);
      setMessage(responseMessages.errorMessageForCatchBlock);
    }
  };

  return (
    <div className="font-FiraSans py-20 px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1">
      <Helmet>
        <title>Buy Instagram Likes – Boost Your IG Engagement Today</title>
        <meta
          name="description"
          content="Want more likes on your Instagram posts? Buy IG likes now to increase engagement and visibility. Fast, reliable, and affordable Instagram likes."
        />
      </Helmet>
      <p className="text-2xl text-center sm:text-lg xs:text-lg pb-5">
        Want More Instagram Likes?
      </p>
      <h1 className="font-bold pb-9 text-5xl text-center sm:text-2xl xs:text-2xl md:text-3xl">
        Boost Your Instagram Engagement with Instagram Likes
      </h1>
      {/* <h3 className="font-bold pb-9 text-5xl text-center sm:text-2xl xs:text-2xl md:text-3xl">
        Buy Instagram Likes to Boost Your Posts Instantly
      </h3> */}
      <div className="p-1 bg-blue-200 text-blue-500 rounded-lg my-2">
        <p className="text-sm sm:text-xs font-semibold xs:text-xs mb-4">
          Improve your Instagram content’s impact and visibility with our real
          Instagram Likes service. Boost your IG posts instantly to draw more
          organic interactions and increase your overall social media presence.
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs mb-2">
          Why Buy Our Instagram Likes?
        </p>
        <div className="pl-1">
          <p className="text-sm sm:text-xs font-semibold xs:text-xs">
            - Likes from real IG accounts that make your posts stand out.
          </p>
          <p className="text-sm sm:text-xs font-semibold xs:text-xs">
            - More likes that can help your content appear more credible and
            attractive to new followers.
          </p>
          <p className="text-sm sm:text-xs font-semibold xs:text-xs">
            - Steady Likes are added as soon as your content goes live,
            maximising your post’s potential during peak engagement times.
          </p>
          <p className="text-sm sm:text-xs font-semibold xs:text-xs">
            - Attract even more views, likes, and comments organically as your
            post's popularity increases.
          </p>
          <p className="text-sm sm:text-xs font-semibold xs:text-xs">
            - No account access is required, keeping your login information
            secure.
          </p>
        </div>
      </div>
      <>
        <FormAndButton
          placeholder="Enter your Instagram Username"
          label="Instagram Username"
          onChange={onChange}
          value={username}
          onClick={onClick}
          mediaUrl={media}
          username={username}
          //onMediaClick={onMediaClick} // Pass onMediaClick callback
          onQuantityChange={onQuantityChange} // Pass onQuantityChange callback
        />
        <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 pt-10 flex justify-center">
          <Button
            type="submit"
            title={loading ? "Sending..." : "Add Likes"}
            variant="primary"
            size="sm"
            onClick={onSubmit}
            disabled={!isAuthenticated || loading}
          />
        </div>
      </>
      <p className="text-center font-FiraSans font-medium text-lg pt-4">
        Looking for likes from real users? Check out our{" "}
        <Link to="/ig_real_oganic_likes" className="text-blue-600">
          real organic likes
        </Link>{" "}
        to get engagement from genuine people interested in your content.
      </p>
      <ToastContainer />
      {/* Success Modal */}
      <SuccessModal
        visible={successModalVisible}
        onClose={() => setSuccessModalVisible(false)}
        message={message}
      />
      {/* Error Modal */}
      <ErrorModal
        visible={errorModalVisible}
        onClose={() => setErrorModalVisible(false)}
        message={message}
      />
      {/* Info Modal */}
      <InfoModal
        visible={infoModalVisible}
        onClose={() => setInfoModalVisible(false)}
        message={message}
      />
    </div>
  );
};

export default InstagramLikes;
