import React, { useEffect } from "react";
import Button from "../../components/shared/Button";
import ThreeRowForm from "../../components/shared/ThreeRowForm";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { orderSubmission } from "../../store/Slice/submitOrderSlice";
import { updateUserDetails } from "../../store/Slice/loginSlice";
import { ErrorModal, SuccessModal } from "../../components/shared/ToastModals";
import { Link, useOutletContext } from "react-router-dom";
import responseMessages from "../../utils/responseMessages";
import { useAccordionContext } from "../../SEO/AccordionContext";
import { Helmet } from "react-helmet-async";

const AutoRealLikes = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { setQuantity } = useOutletContext();
  const { setAccordionData, setAccordionHeading, setReviews } =
    useAccordionContext();
  const [data, setData] = useState({
    link: [],
    quantity: [],
    peopleCategory: "",
    numberOfPost: "",
    likesQuantity: "",
    service_type: window.location.pathname,
  });
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [message, setMessage] = useState("");
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const { link, numberOfPost, peopleCategory, likesQuantity } = data;
  const onChange = (e) => {
    let updated = {};
    if (e.target.name === "link") {
      updated = { [e.target.name]: [e.target.value] };
    } else {
      updated = { [e.target.name]: e.target.value };
    }
    setData({
      ...data,
      ...updated,
    });
    const value = { ...data, ...updated };
    const { numberOfPost, likesQuantity } = value;
    const total = numberOfPost * likesQuantity;
    setQuantity(total);
    setData((prevData) => ({
      ...prevData,
      quantity: [total],
    }));
  };
  useEffect(() => {
    setQuantity(0);
    setAccordionData([]);
    setAccordionHeading("");
    setReviews([]);
  }, []);
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await dispatch(orderSubmission(data));
      if (response.payload.code === 200 || response.payload.code === 201) {
        setQuantity(0);
        setSuccessModalVisible(true);
        setMessage(responseMessages.notAvailableOrderMessage);
        await dispatch(updateUserDetails());
        setData({
          link: [],
          quantity: [],
          peopleCategory: "",
          likesQuantity: "",
          numberOfPost: "",
          service_type: window.location.pathname,
        });
      } else {
        setQuantity(0);
        setErrorModalVisible(true);
        if (response.payload.message === "insufficient wallet balance") {
          setMessage(responseMessages.insufficientBalance);
        } else {
          setMessage(responseMessages.orderFailure);
        }
      }
      setLoading(false);
    } catch (error) {
      setQuantity(0);
      setLoading(false);
      setErrorModalVisible(true);
      setMessage(responseMessages.errorMessageForCatchBlock);
    }
  };
  return (
    <div className="font-FiraSans py-8 px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1">
      <Helmet>
        <title>
          Auto Real Instagram Likes – Get Real Engagement Automatically{" "}
        </title>
        <meta
          name="description"
          content="Auto real Instagram likes from genuine users! Automate your engagement with real likes that help build credibility and boost visibility. Sign up today."
        />
      </Helmet>
      <h1 className="font-bold pb-5 text-4xl text-center sm:text-xl xs:text-xl md:text-2xl">
        Auto Real Instagram Likes – Real Engagement, Automated
      </h1>
      <p className="font-medium pb-9 text-3xl text-center sm:text-xl xs:text-xl md:text-2xl">
        Add Auto Real Likes
      </p>
      <div className="flex mb-7 flex-col gap-4 p-3 bg-blue-200 text-blue-500 rounded-lg">
        <div>
          <p className="text-base sm:text-xs xs:text-xs">
            Tired of managing likes for every Instagram post? With our auto real
            likes service, you’ll receive likes from real Instagram users
            automatically, ensuring every post gets the engagement it deserves.
            This service is perfect for those who want to maintain authenticity
            while also keeping up with a consistent posting schedule.
          </p>
        </div>
        <div>
          <p className="text-base sm:text-xs xs:text-xs mb-2">
            Why Auto Real Instagram Likes?
          </p>
          <p className="text-sm sm:text-xs xs:text-xs pl-2">
            - <strong>Real Engagement:</strong> All likes come from real
            Instagram users, helping you maintain credibility while growing your
            audience.
          </p>
          <p className="text-sm sm:text-xs xs:text-xs pl-2">
            - <strong>Automatic Delivery:</strong>Every time you post, likes are
            delivered automatically, ensuring that your content always gets the
            boost it needs.
          </p>
          <p className="text-sm sm:text-xs xs:text-xs pl-2">
            - <strong>Perfect for Influencers and Brands:</strong> Whether
            you’re an influencer, a business, or someone growing a personal
            brand, auto real likes make it easy to maintain engagement on every
            post.
          </p>
        </div>
      </div>
      <div className="p-3 bg-blue-200 text-blue-500 rounded-lg">
        <p className="text-sm sm:text-xs xs:text-xs text-[#303efb]">
          Choose number of future posts and Auto real IG likes package
        </p>
        <p className="text-sm sm:text-xs xs:text-xs text-[#303efb]">
          The likes are 100% real(no one has in the market)
        </p>
        <p className="text-sm sm:text-xs xs:text-xs text-[#303efb]">
          You can choose if you want only female or only male real likes on each
          post
        </p>
      </div>
      <>
        <ThreeRowForm
          labelOne="Instagram Username"
          labelTwo="Number of Posts"
          labelThree="Auto Real Likes"
          placeholderOne="Instagram Username"
          onChangeOne={onChange}
          onClick={onChange}
          onChangeThree={onChange}
          valueOne={link}
          valueTwo={numberOfPost}
          valueThree={likesQuantity}
          nameOne="link"
          nameTwo="numberOfPost"
          nameThree="likesQuantity"
        />
        <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1">
          <label className="block text-lg">Female / Male:</label>
          <select
            id="peopleCategory"
            name="peopleCategory"
            value={peopleCategory}
            onChange={onChange}
            className="w-2/3 sm:w-full xs:w-full px-2 py-3 rounded-md shadow-md outline-blue-300 border-2 border-gray-400 text-gray-600"
          >
            <option value="" disabled selected>
              Select Gender
            </option>
            <option value="Mixed">Mixed</option>
            <option value="Female">Female</option>
            <option value="Male">Male</option>
          </select>
        </div>
        <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 pt-10 flex justify-center">
          <Button
            type="submit"
            title={loading ? "Sending likes..." : "Auto Likes"}
            variant="primary"
            size="sm"
            onClick={onSubmit}
            disabled={
              !link ||
              !numberOfPost ||
              !likesQuantity ||
              !peopleCategory ||
              loading ||
              !isAuthenticated
            }
          />
        </div>
      </>
      <p className="text-center font-FiraSans font-medium text-lg pt-4">
        Looking for likes that grow your account organically? Our{" "}
        <Link to="/ig_real_oganic_likes" className="text-blue-600">
          real organic likes
        </Link>{" "}
        are perfect for boosting credibility with real users.
      </p>
      {/* Success Modal */}
      <SuccessModal
        visible={successModalVisible}
        onClose={() => setSuccessModalVisible(false)}
        message={message}
      />
      {/* Error Modal */}
      <ErrorModal
        visible={errorModalVisible}
        onClose={() => setErrorModalVisible(false)}
        message={message}
      />{" "}
    </div>
  );
};

export default AutoRealLikes;
