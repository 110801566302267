import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "animate.css";
import { Pagination } from "antd";
import { Helmet } from "react-helmet-async";
import { ApiService } from "../../services/apiService";

const Index = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [displayedBlogs, setDisplayedBlogs] = useState([]);
  const [totalBlogs, setTotalBlogs] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5;
  const navigate = useNavigate();

  const fetchBlogPosts = async () => {
    try {
      const response = await ApiService.getAllBlogs();
      const blogs = response.data.data;
      console.log(response.data.data);
      setBlogPosts(blogs);
      setTotalBlogs(blogs.length);
      setDisplayedBlogs(blogs.slice(0, pageSize));
    } catch (error) {
      console.error("Failed to fetch blog posts", error);
    }
  };

  useEffect(() => {
    fetchBlogPosts();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    setDisplayedBlogs(blogPosts.slice(startIndex, endIndex));
  };

  const handleReadMore = (heading) => {
    navigate(`/blog/${encodeURIComponent(heading)}`);
  };

  const renderBlogContent = (content, heading) => {
    const anchorStyle = {
      color: "blue",
      textDecoration: "underline",
    };

    const styledContent = content.replace(
      /<a /g,
      `<a style="${Object.keys(anchorStyle)
        .map((key) => `${key}: ${anchorStyle[key]};`)
        .join(" ")}" target="_blank"`
    );
    return (
      <div className="flex flex-col items-center">
        <p
          className="font-FiraSans"
          dangerouslySetInnerHTML={{
            __html: `${styledContent.slice(0, 500)}...`,
          }}
        />
        <button
          className="text-[#f49a39] border-[#f49a39] border p-1 rounded-xl cursor-pointer"
          onClick={() => handleReadMore(heading)}
        >
          Read More
        </button>
      </div>
    );
  };

  return (
    <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 pt-2 pb-6 font-FiraSans">
      <Helmet>
        <title>Expert Insights and Tips</title>
        <meta
          name="description"
          content="Expert advice, industry trends, latest updates and tips to boost your social media engagement"
        />
      </Helmet>
      <h1 className="font-bold text-center text-4xl lg:text-2xl md:text-2xl sm:text-xl xs:text-xl">
        Blog Articles
      </h1>
      {totalBlogs > 0 ? (
        <>
          {displayedBlogs.map((blogPost) => (
            <div key={blogPost.blog_id} className="mt-4">
              <h1 className="font-Montserrat text-lg font-semibold text-center">
                {blogPost.heading}
              </h1>
              {renderBlogContent(blogPost.content, blogPost.heading)}
            </div>
          ))}
          <div className="flex justify-center mt-4">
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={totalBlogs}
              onChange={handlePageChange}
            />
          </div>
        </>
      ) : (
        <div className="flex items-center py-10 justify-center">
          <p className="font-medium text-2xl mb-8 animate__animated animate__bounceInLeft animate__infinite animate__slower">
            There are no available blog posts at the moment...
          </p>
        </div>
      )}
    </div>
  );
};

export default Index;

// import React, { useEffect, useState } from "react";
// import { ToastContainer } from "react-toastify";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import "animate.css";

// const Index = () => {
//   const [blogPosts, setBlogPosts] = useState([]);
//   const navigate = useNavigate();
//   const totalPage = blogPosts.length;
//   const currentPage = 0;

//   const fetchBlogPosts = async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_IGCARTS_BASE_URL}/blog/get-blog?limit=100`,
//         {
//           headers: {
//             "content-type": "application/json; charset=utf-8",
//           },
//         }
//       );
//       setBlogPosts(response.data.data);
//     } catch (error) {
//       console.error("Failed to fetch blog posts", error);
//     }
//   };

//   useEffect(() => {
//     fetchBlogPosts();
//   }, []);

//   const handleReadMore = (blog_id) => {
//     navigate(`/blog/${blog_id}`);
//   };

//   const renderBlogContent = (content, blog_id) => {
//     const anchorStyle = {
//       color: "blue",
//       textDecoration: "underline",
//     };

//     const styledContent = content.replace(
//       /<a /g,
//       `<a style="${Object.keys(anchorStyle)
//         .map((key) => `${key}: ${anchorStyle[key]};`)
//         .join(" ")}" target="_blank"`
//     );
//     return (
//       <div className="flex flex-col items-center">
//         <p
//           className="font-FiraSans"
//           dangerouslySetInnerHTML={{
//             __html: `${styledContent.slice(0, 500)}...`,
//           }}
//         />
//         <button
//           className="text-[#f49a39] border-[#f49a39] border p-1 rounded-xl cursor-pointer"
//           onClick={() => handleReadMore(blog_id)}
//         >
//           Read More
//         </button>
//       </div>
//     );
//   };

//   return (
//     <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 pt-2 pb-6 font-FiraSans">
//       <h1 className="font-bold text-center text-4xl lg:text-2xl md:text-2xl sm:text-xl xs:text-xl">
//         Blog Articles
//       </h1>
//       {blogPosts.length > 0 ? (
//         blogPosts.map((blogPost) => (
//           <div key={blogPost.blog_id} className="mt-4">
//             <h1 className="font-Montserrat text-lg font-semibold text-center">
//               {blogPost.heading}
//             </h1>
//             {renderBlogContent(blogPost.content, blogPost.blog_id)}
//           </div>
//         ))
//       ) : (
//         <div className="flex items-center py-10 justify-center">
//           <p className="font-medium text-2xl mb-8 animate__animated animate__bounceInLeft animate__infinite animate__slower">
//             There are no available blog posts at the moment...
//           </p>
//         </div>
//       )}
//       <ToastContainer />
//     </div>
//   );
// };

// export default Index;

// import React, { useEffect, useState } from "react";
// import { ToastContainer, toast } from "react-toastify";
// import axios from "axios";
// import DOMPurify from "dompurify"; // For sanitizing HTML content
// import "animate.css";
// import "react-toastify/dist/ReactToastify.css"; // Import Toastify styles

// const Index = () => {
//   const [blogPosts, setBlogPosts] = useState([]);
//   const [showFullContent, setShowFullContent] = useState({});
//   const [loading, setLoading] = useState(true); // State for loading

//   // Fetch Blog Posts
//   const fetchBlogPosts = async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_IGCARTS_BASE_URL}/blog/get-blog`,
//         {
//           headers: {
//             "content-type": "application/json; charset=utf-8",
//           },
//         }
//       );
//       setBlogPosts(response.data.data);
//     } catch (error) {
//       toast.error("Failed to load blog posts. Please try again later.");
//       console.error("Error fetching blog posts:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchBlogPosts();
//   }, []);

//   // Toggle Full Content for "Read More/Less"
//   const toggleContent = (index) => {
//     setShowFullContent((prevState) => ({
//       ...prevState,
//       [index]: !prevState[index],
//     }));
//   };

//   // Render Blog Content with sanitization
//   const renderBlogContent = (content, index) => {
//     return (
//       <div className="flex flex-col items-center">
//         <p
//           className="font-FiraSans blog-content"
//           dangerouslySetInnerHTML={{
//             __html: showFullContent[index]
//               ? DOMPurify.sanitize(content)
//               : DOMPurify.sanitize(`${content.slice(0, 500)}...`),
//           }}
//         />
//         {showFullContent[index] ? (
//           <button
//             aria-label="Show less content"
//             className="text-[#f49a39] border-[#f49a39] border p-1 rounded-xl cursor-pointer"
//             onClick={() => toggleContent(index)}
//           >
//             Read Less
//           </button>
//         ) : (
//           <button
//             aria-label="Show more content"
//             className="text-[#f49a39] border-[#f49a39] border p-1 rounded-xl cursor-pointer"
//             onClick={() => toggleContent(index)}
//           >
//             Read More
//           </button>
//         )}
//       </div>
//     );
//   };

//   return (
//     <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 pt-2 pb-6 font-FiraSans">
//       <h1 className="font-bold text-center text-4xl lg:text-2xl md:text-2xl sm:text-xl xs:text-xl">
//         Blog Articles
//       </h1>
//       {loading ? (
//         // Tailwind CSS Spinner for Loading
//         <div className="flex justify-center items-center h-96">
//           <div className="w-16 h-16 border-4 border-dashed rounded-full animate-spin border-gray-500"></div>
//         </div>
//       ) : blogPosts.length > 0 ? (
//         blogPosts.map((blogPost, index) => (
//           <div key={index} className="mt-4">
//             <h1 className="font-Montserrat text-lg font-semibold text-center">
//               {blogPost.heading}
//             </h1>
//             {renderBlogContent(blogPost.content, index)}
//           </div>
//         ))
//       ) : (
//         <div className="flex items-center py-10 justify-center">
//           <p className="font-medium text-2xl mb-8 animate__animated animate__bounceInLeft animate__infinite animate__slower">
//             There are no available blog posts at the moment...
//           </p>
//         </div>
//       )}
//       <ToastContainer />
//     </div>
//   );
// };

// export default Index;
